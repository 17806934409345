const More = column => {
  if (column?.videos) {
    if (column.videos.length === 1) {
      return "Se video her"
    } else {
      return `Se ${column.videos.length} videoer her`
    }
  }
  return "Se mere her"
}

export default More
