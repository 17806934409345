import React from "react"
import { graphql } from "gatsby"
import Layout from "components/layout"
import SEO from "components/seo"
import Tile3 from "components/tile3"
import Breadcrumb from "components/breadcrumb"
import More from "components/more"
import Ad from "components/ad"

const IndexPage = ({ data }) => {
  const items = data.allGraviditetFoedselOgBarselYaml.nodes.map(item => {
    return { ...item, link: item.fields.slug }
  })
  const title = "Graviditet, fødsel og barsel"
  const breadcrumbs = [[title, "#"]]

  return (
    <Layout>
      <SEO title={title} />

      <section className="section">
        <div className="container">
          <Breadcrumb items={breadcrumbs} />

          <Ad />

          <Tile3 items={items} linkText={More} />
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query GetGraviditetFoedselOgBarsel {
    allGraviditetFoedselOgBarselYaml(sort: { fields: title, order: ASC }) {
      nodes {
        fields {
          slug
        }
        link
        title
        image
        videos
      }
    }
  }
`
